<template>
  <div style="position: fixed; left: 0; top: 0; width: 100vw; margin: 0;">
    <v-container
      :style="`padding-top: 5vh; background-color: ${template.colors.primary}; color: ${template.colors.inline_primary_text}; width: 100vw; height: 100vh;`"
    >
      <v-row justify="center" class="mt-5 pt-5" style="height: 90%;">
        <v-col cols="12" class="text-center" v-if="!camera" align-self="center">
          <v-card
            class="rounded-xl"
            :color="template.colors.blocks"
            :style="`color: ${template.colors.block_text};`"
            :dark="template.isDark(template.colors.blocks)"
            :light="!template.isDark(template.colors.blocks)"
            flat
            @click="camera = true"
          >
            <v-row justify="center" class="pa-4">
              <v-col cols="auto" align-self="center">
                <v-icon large>mdi-qrcode-scan</v-icon>
              </v-col>
              <v-col cols="auto" align-self="center">
                <h3>JETZT QR-CODE SCANNEN</h3>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          class="mt-5 pt-5 text-center"
          v-if="camera && camera_loading"
        >
          <h2><v-icon large>mdi-qrcode-scan</v-icon></h2>
          <h3>KAMERA WIRD GELADEN...</h3>
        </v-col>
        <v-col cols="12" class="mt-5 pt-5" v-if="!scanned_data && camera">
          <v-card class="rounded-xl pa-0">
            <v-row class="pa-0">
              <v-col cols="12" class="pa-0">
                <qrcode-stream @detect="onDetect" @init="onInit" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          v-if="scanned_data"
          align-self="center"
          class="mt-5 text-center"
        >
          <v-row justify="center" style="min-height: 60vh;">
            <v-col cols="12" align-self="center">
              <h2><v-icon large>mdi-check-circle</v-icon></h2>
              <h3>QR-Code erkannt</h3>
            </v-col>
            <v-col cols="10" class="mt-3">
              <v-card
                class="rounded-xl"
                :color="template.colors.blocks"
                :style="`color: ${template.colors.block_text};`"
                :dark="template.isDark(template.colors.blocks)"
                :light="!template.isDark(template.colors.blocks)"
                :href="
                  scanned_data.verband != template.var.verband
                    ? scanned_data.full_link
                    : scanned_data.link
                "
              >
                <v-row justify="center">
                  <v-col cols="auto" class="ml-3">
                    <v-avatar tile>
                      <v-img :src="scanned_data.logo" />
                    </v-avatar>
                  </v-col>
                  <v-col class="text-left">
                    <h3>{{ scanned_data.name }}</h3>
                    <h4 class="font-weight-light">
                      {{ scanned_data.sportstaette }}
                    </h4>
                  </v-col>
                  <v-col cols="auto" align-self="center">
                    <v-icon large>mdi-chevron-right</v-icon>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import { mapGetters } from 'vuex'
//import firebase from '../../firebaseConfig'

export default {
  name: 'qrcodescan',
  data() {
    return {
      camera_loading: true,
      scanned_data: '',
      camera: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
  components: {
    QrcodeStream,
  },
  methods: {
    async onDetect(promise) {
      try {
        const {
          imageData, // raw image data of image/frame
          content, // decoded String or null
          location, // QR code coordinates or null
        } = await promise

        if (content === null) {
          // decoded nothing
        } else {
          var url = content.split('/')
          if (url.length == 5) {
            firebase
              .firestore()
              .collection('User')
              .where('alias', '==', url[3])
              .get()
              .then((snap) => {
                snap.forEach((v) => {
                  var verein = v.data()
                  var sportstaette = ''
                  if (verein.sportstaetten) {
                    if (verein.sportstaetten.length > 1) {
                      verein.sportstaetten.forEach((platz) => {
                        if (platz.link == url[4]) {
                          sportstaette = platz.name
                        }
                      })
                    } else {
                      sportstaette = verein.sportstaetten[0].name
                    }
                  }

                  this.scanned_data = {
                    name: verein.verein.name,
                    logo: verein.verein.logo,
                    verband: verein.verband,
                    full_link:
                      this.template.getVerband(verein.verband).url +
                      '/' +
                      url[3] +
                      '/' +
                      url[4],
                    link: '/' + url[3] + '/' + url[4],
                    sportstaette: sportstaette,
                  }
                })
              })
          } else {
            firebase
              .firestore()
              .collection('User')
              .where('alias', '==', url[3])
              .get()
              .then((snap) => {
                snap.forEach((v) => {
                  var verein = v.data()
                  var sportstaette = ''
                  if (verein.sportstaetten) {
                    sportstaette = verein.sportstaetten[0].name
                  }
                  this.scanned_data = {
                    name: verein.verein.name,
                    logo: verein.verein.logo,
                    full_link:
                      this.template.getVerband(verein.verband).url +
                      '/' +
                      url[3],
                    link: '/' + url[3],
                    sportstaette: sportstaette,
                  }
                })
              })
          }
        }
      } catch (error) {
        // ...
        console.log(error)
      }
    },
    async onInit(promise) {
      try {
        this.camera_loading = true
        await promise
        this.camera_loading = false
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser'
        }
        this.camera_loading = false
      }
    },
  },
}
</script>
